







import { Component, Vue } from 'vue-property-decorator';
import Body from '@/views/Body.vue';
import Navbar from '@/views/Navbar.vue';
import AppFooter from '@/views/AppFooter.vue';

@Component({
  components: { Body, Navbar, AppFooter }
})
export default class App extends Vue {}
