



























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Privacy extends Vue {
  @Prop() game!: string;

}
