














































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class QuizzzNite extends Vue {
  clickAndroid() {
    window.open('https://play.google.com/store/apps/details?id=games.usta.convergists', '_blank');
  }
}
