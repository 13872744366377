






















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Contact extends Vue {
  name = '';
  email = '';
  subject = 'Quizzz Nite';
  message = '';

  mounted() {
    switch (this.$route.name) {
      case 'piano-contact':
        this.subject = 'Piano Shooter';
        break;
      case 'quizzz-nite-contact':
        this.subject = 'Quizzz Nite';
        break;
      case 'jumpilla-contact':
        this.subject = 'Jumpilla';
        break;
      case 'convergists-contact':
        this.subject = 'Convergists'
        break;
    }
  }

  get enabled() {
    const e = this.name.length * this.email.length * this.message.length;
    console.log(e);
    return e;
  }
}
